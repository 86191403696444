<template>
  <div>
    <!-- TODO 封装 -->
    <div class="top-nav theme-bg">
      <div class="flex fsbc">
        <div class="system-label">
          <i class="el-icon-s-platform"></i>
          简投实业消纳场票据系统
        </div>
        <div class="user-name">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <i class="el-icon-s-custom"></i>
              {{ userInfo.curUserInfo && userInfo.curUserInfo.username }}
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="setUserIfon" v-if="userInfo.role == 'GROUP_MANAGER'">
                个人设置
              </el-dropdown-item>
              <el-dropdown-item command="setAccount" v-else>账号设置</el-dropdown-item>
              <el-dropdown-item command="out">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="flex flt system-page-main">
      <div class="side-bar">
        <el-menu
          router
          active-text-color="#ffffff"
          :default-active="curRootPath"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
        >
          <template v-for="(menu, index) in menuLists">
            <template v-if="!menu.meta.hasSubmenu && menu.isShow">
              <el-menu-item v-for="(item, index) in menu.children" :index="item.path" :key="index">
                <i :class="item.meta.icon"></i>
                <span class="menu-size" slot="title">{{ item.meta.title }}</span>
              </el-menu-item>
            </template>
            <template v-if="menu.meta.hasSubmenu && menu.isShow">
              <el-submenu :index="menu.path" :key="index">
                <template slot="title">
                  <i :class="menu.meta.icon"></i>
                  <span class="clo6 menu-size">{{ menu.meta.title }}</span>
                </template>
                <div class="el-menu-item-wrap" v-for="(item, index) in menu.children" :key="index">
                  <el-menu-item v-if="item.isShow" :index="item.path">
                    <span class="menu-child-item menu-child-size" slot="title">
                      {{ item.meta.title }}
                    </span>
                  </el-menu-item>
                </div>
              </el-submenu>
            </template>
          </template>
        </el-menu>
      </div>

      <div class="right-main page" @scroll="scrollEvent">
        <div class="page-title flex fsbc">
          <div class="fontb clo3">
            {{ $route.meta.title }}
          </div>
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              :to="{ path: item.path }"
              v-for="(item, i) in breadcrumbData"
              :key="i">
              {{item.title}}
            </el-breadcrumb-item>
          </el-breadcrumb> -->
          <div class="refresh">
            <i
              class="el-icon-refresh"
              :class="{ rotateAnimation: isRefreshIng }"
              @click="layoutEmit('refresh')"
            />
            <i class="el-icon-close ml10"
              v-if="$route.name != 'Home'"
              @click="layoutEmit('close')" />
          </div>
        </div>
        <div class="page-main" :style="`max-height:${mainPageHeight}vh`">
          <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive> -->
              <router-view />
            <!-- </keep-alive> -->
          </transition>
        </div>
        <div class="page-footer" v-if="footerInfo.isShowFooter">
          <el-button type="primary"
            v-if="footerInfo.confirmText"
            @click="layoutEmit('onConfirm')">
            {{footerInfo.confirmText}}
          </el-button>
          <el-button v-if="footerInfo.cancelText"
            @click="layoutEmit('onCancel')">
            {{footerInfo.cancelText}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import menuList from '../config/menuData';
import {
  $gp_getCurUser,
  // $gp_getCurProInfo,
  // $gp_getCurQtcInfo,
} from '../api/index';
import { debounce } from '../utils/index';

export default {
  data() {
    return {
      isCollapse: false,
      sideBarWidth: 180,
      curRootPath: '/home',
      menuLists: menuList(),
      isRefreshIng: false, // 刷新中
      refreshInfo: { roureName: '', timespace: '' },
      count: 0,
      scrollEvent: debounce(this.scroll, 300),
      isShowFooter: false, // 是否显示底部导航
      breadcrumbData: [], // 页包屑数据
    };
  },
  watch: {
    $route(val) {
      // this.getBreadcrumbData();
      this.curRootPath = `/${val.path.split('/')[1]}`;
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'footerInfo', 'updateTime']),

    // 主页面高度
    mainPageHeight: {
      get() {
        return this.footerInfo.isShowFooter ? 73.5 : 82;
      },
      set(val) { return val; },
    },
  },
  created() {
    console.log(this.$router.options.routes);
    this.getCurUser();
  },
  methods: {
    ...mapActions(['setUserInfo', 'setUpdateTime']),
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    triggerSideMenu() {
      this.isCollapse = !this.isCollapse;
      this.sideBarWidth = this.isCollapse ? 64 : 180;
    },
    logOut() {
      this.$storage.remove('userInfo');
      this.setUserInfo({});
      this.$router.push('/login');
      // $gp_logout();
    },
    getBreadcrumbData() {
      const { matched } = this.$route;
      const home = { title: '首页', path: '/' };
      let breadcrumbData = [];
      if (!matched[0].path || !matched[0].meta.title) {
        home.isShowPath = false;
        breadcrumbData.push(home);
      } else {
        const paths = matched.map((item, index) => {
          console.log('index', index);
          const { title } = item.meta;
          const { path } = item;
          const { isShowPath } = index === (matched.length - 1);
          return { title, path, isShowPath };
        });
        breadcrumbData = [home, ...paths];
      }
      console.log('breadcrumbData', breadcrumbData);
      this.breadcrumbData = breadcrumbData;
    },
    getCurUser() {
      const { userInfo } = this;
      $gp_getCurUser().then((res) => {
        console.log(res, '--------');
        const curUserInfo = res;
        this.setUserInfo({ ...userInfo, curUserInfo });
      });
    },
    handleCommand(command) {
      if (command === 'out') {
        this.logOut();
      }
      if (command === 'setUserIfon') {
        this.resetPwd();
      }
      if (command === 'setAccount') {
        this.resetAccount();
      }
    },

    // 页面底部区域是否显示
    setFooterInfo(isShowFooter, confirmText, cancelText) {
      this.footerInfo = {
        isShowFooter,
        confirmText,
        cancelText,
      };
      console.log('this.footerInfo=======>', this.footerInfo);
    },
    // 刷新事件
    layoutEmit(tag) {
      this.getBreadcrumbData();
      this.undateEmitInfo(tag);
      if (tag === 'refresh') {
        this.isRefreshIng = true;
        setTimeout(() => {
          this.isRefreshIng = false;
        }, 1000);
      }
    },
    // 更新外发事件信息
    undateEmitInfo(tag) {
      const routeName = this.$route.name;
      const updateTime = new Date().getTime();
      console.log(updateTime);
      this.setUpdateTime({ updateTime, routeName, tag });
    },
    resetPwd() {
      this.$router.push({ name: 'ResetPwd' });
    },
    resetAccount() {
      if (this.userInfo.role === 'PROJECT_MANAGER') {
        this.$router.push({ name: 'ResetProAccount' });
      } else {
        this.$router.push({ name: 'ResetQtcAccount' });
      }
    },
    scroll(e) {
      console.log('scroll', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-nav {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100vw;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  color: #fff;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  .system-label {
    font-size: 18px;
    font-weight: 600;
  }
  i {
    font-size: 16px;
  }
  .el-dropdown-link {
    color: #fff;
    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
  div > span {
    padding: 0 5px;
  }
}
.system-page-main {
  padding-top: 54px;
  .side-bar {
    width: 200px;
    transition: all 0.4s;
    height: 90vh;
    overflow-y: auto;
    .forwork-name {
      width: 104px;
      // height: 80px;
      margin: 15px auto;
      padding: 8px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #f5faf7;
      p {
        color: #666;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .el-menu {
      border-right: none;
    }
    .el-menu-item.is-active {
      position: relative;
      background-color: #1abc9c !important; //你要修改的颜色
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #013a2e;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 40%;
        right: -5px;
        background: #f5f7fa;
        transform: translateY(-50%);
        transform: rotate(45deg);
      }
    }
    .el-menu-vertical-demo {
      height: 90vh;
    }
    .menu-child-item {
      padding-left: 10px;
    }
    .home-item {
      padding-left: 30px;
    }
    .clo9 {
      color: #999;
    }
    .menu-size {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .side-bar::-webkit-scrollbar {
    /*滚动条整体样式*/

    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/

    height: 1px;
  }

  .right-main::-webkit-scrollbar {
    /*滚动条整体样式*/

    width: 0; /*高宽分别对应横竖滚动条的尺寸*/

    height: 1px;
  }
  .right-main::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/

    border-radius: 10px;

    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    background: #919090;
  }
  .right-main::-webkit-scrollbar-track {
    /*滚动条里面轨道*/

    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    background: #ededed;
  }
  .right-main {
    position: relative;
    flex: 1;
    width: 80%;
    height: 90vh;
    overflow-y: auto;
    background: #fff;
    .page-title {
      padding: 12px 20px;
      border-bottom: 1px solid #efefef;
      background-color: #fff;
      border-left: 1px solid #f2f2f2;
    }
    .page-main::-webkit-scrollbar {
      /*滚动条整体样式*/

      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/

      height: 1px;
    }
    .page-main::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/

      border-radius: 10px;

      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

      background: #919090;
    }
    .page-main::-webkit-scrollbar-track {
      /*滚动条里面轨道*/

      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

      border-radius: 10px;

      background: #ededed;
    }
    .page-main {
      padding: 10px;
      padding-bottom: 0;
      overflow-y: auto;
      background:#efefef
    }
    .page-footer {
      background-color: #fff;
      padding: 10px;
      text-align: center;
      border: 1px solid #ededed;
    }
  }
  .rotateAnimation {
    animation: rotate 0.5s infinite;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
