/*
 * @Author: 许波
 * @Date: 2021-02-08 22:36:56
 * @LastEditTime: 2023-02-09 23:23:54
 */
import { post } from '../utils/index';

// 登陆
export const $gp_login = (params) => post('/manager/v3/sign-in', params);

// 修改密码
export const $gp_editPwd = (params) => post('/manager/v3/update/password', params);

// 注销登录
export const $gp_logout = (params) => post('/api/common/account/web/v1/logout', params);

// 创建集团用户账号
export const $gp_addAccount = (params) => post('/manager/v3/create', params);

// 删除集团用户账号
export const $gp_delAccount = (params) => post('/manager/v3/del/{managerUuid}', params);

// 获取当前集团用户账号信息
export const $gp_getCurUser = (params) => post('/manager/v3/info', params);

// 获取当前集团用户账号信息
export const $gp_getGroupUser = (params) => post('/api/group/account/web/v1/detail/groupUser', params);

// 获取集团用户账号列表
export const $gp_getGroupList = (params) => post('/manager/v3/page', params);

// 重置集团用户密码
export const $gp_resetGroupUserPwd = (params) => post('/manager/v3/reset-password/{managerUuid}', params);

// 修改集团用户账号
export const $gp_editGroupUser = (params) => post('/manager/v3/modify/{managerUuid}', params);

// 获取集团用户的所有权限
export const $gp_getGroupRole = (params) => post('/privilege/v3/list', params);

// 获取集团首页数据
export const $gp_getGroupHomeData = (params) => post('/api/group/page/web/v1/index', params);

/** 项目登陆 * */

// 获取当前项目信息
export const $gp_getCurProInfo = (params) => post('/api/project/project/web/v1/current', params);

// 获取集团首页数据
export const $gp_getProHomeData = (params) => post('/api/project/page/web/v1/index', params);

// 获取首页数据
export const $gp_getHomeData = (params) => post('/manager/v3/page/index-statistics', params);
