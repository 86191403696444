/*
 * @Author: 许波
 * @Date: 2021-07-09 12:56:09
 * @LastEditTime: 2023-02-16 00:05:51
 */
// @ts-nocheck
import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/home/home.vue';
/* Layout */
import storage from 'good-storage';
import store from '../store';
import Layout from '../layout/index.vue';
import pageFooterConfig from '../config/pageFooterConfig';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    isShow: true,
    meta: { hasSubmenu: false },
    children: [
      {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/home'),
        name: 'Home',
        meta: {
          title: '首页', icon: 'el-icon-house', code: 'home',
        },
      },
    ],
  },
  {
    path: '/resetPwd',
    component: Layout,
    redirect: '/resetPwd',
    meta: { title: '账号管理', hasSubmenu: true, icon: 'el-icon-folder' },
    children: [
      {
        path: '/home/resetPwd',
        component: () => import(/* webpackChunkName: "home" */ '@/views/base/resetPwd'),
        name: 'ResetPwd',
        meta: { title: '个人设置' },
      },
      {
        path: '/home/resetProAccount',
        component: () => import(/* webpackChunkName: "home" */ '@/views/base/resetPwd'),
        name: 'ResetProAccount',
        meta: { title: '账号设置' },
      },
    ],
  },
  {
    path: '/ticketDistribution',
    component: Layout,
    redirect: '/ticketDistribution',
    meta: {
      title: '票据管理', hasSubmenu: true, code: 'coupon_manage', icon: 'el-icon-toilet-paper',
    },
    children: [
      {
        path: '/ticketRecord',
        component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketRecord'),
        name: 'TicketRecord',
        meta: { title: '票据分配', code: 'coupon_allocate' },
        children: [
          {
            path: '/ticketRecord/ticketDistribution',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketDistribution'),
            name: 'TicketDistribution',
            meta: { title: '票据分配', code: 'coupon_allocate' },
          },
          {
            path: '/ticketRecord/ticketDistributionDetail',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketDistributionDetail'),
            name: 'TicketDistributionDetail',
            meta: { title: '票据分配详情' },
          },
        ],
      },
      {
        path: '/assignApprover',
        component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketRecord'),
        name: 'AssignApprover',
        meta: { title: '分票审批', code: 'allocate_approve' },
        children: [
          {
            path: '/assignApprover/assignApproverChecked',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketDistributionDetail'),
            name: 'AssignApproverChecked',
            meta: { title: '票据分配审核' },
          },
          {
            path: '/assignApprover/assignApproverDetail',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketDistributionDetail'),
            name: 'AssignApproverDetail',
            meta: { title: '票据分配详情' },
          },
        ],
      },
      {
        path: '/ticketCount',
        component: () => import(/* webpackChunkName: "ticket" */ '@/views/ticket/ticketCount'),
        name: 'TicketCount',
        meta: { title: '票据统计', code: 'coupon_approve' },
      },
    ],
  },
  {
    path: '/transportRecord',
    component: Layout,
    redirect: '/transportRecord',
    meta: { title: '运输管理', hasSubmenu: true, icon: 'el-icon-truck' },
    children: [
      {
        path: '/transportCheckin',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportCheckin'),
        name: 'TransportCheckin',
        meta: { title: '人工补录', code: 'manual_input', role: 'GROUP_MANAGER' },
        children: [
          {
            path: '/transportCheckin/transportCreate',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportCreate'),
            name: 'TransportCreate',
            meta: { title: '添加登记', role: 'GROUP_MANAGER' },
          },
          {
            path: '/transportCheckin/transportEdit',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportCreate'),
            name: 'TransportEdit',
            meta: { title: '修改登记', role: 'GROUP_MANAGER' },
          },
          {
            path: '/transportCheckin/transportDetail',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportDetail'),
            name: 'TransportDetail',
            meta: { title: '登记详情', role: 'GROUP_MANAGER' },
          },
        ],
      },
      {
        path: '/transportPrequalify',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportPrequalify'),
        name: 'TransportPrequalify',
        meta: { title: '补录预审', code: 'input_pre_approve', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
        children: [
          {
            path: '/transportPrequalify/pre-approve',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportPrequalifyDetail'),
            name: 'PreApprove',
            meta: { title: '预审详情', role: 'GROUP_MANAGER' },
          },
        ],
      },
      {
        path: '/transportApprove',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportApprove'),
        name: 'TransportApprove',
        meta: { title: '补录终审', code: 'input_approve', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
        children: [
          {
            path: '/transportApprove/approve',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportDetail'),
            name: 'Approve',
            meta: { title: '终审详情', role: 'GROUP_MANAGER' },
          },
        ],
      },
      {
        path: '/transportRecord',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportRecord'),
        name: 'TransportRecord',
        meta: { title: '运输记录', code: 'trans_record', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
      },
      {
        path: '/transportCount',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportCount'),
        name: 'TransportCount',
        meta: { title: '运输统计', code: 'trans_statistics', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
        children: [
          {
            path: '/transportCount/transportCountDetail',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportCountDetail'),
            name: 'TransportCountDetail',
            meta: { title: '运输统计明细', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
          },
        ],
      },
      {
        path: '/transportCancel',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/transportCancel'),
        name: 'TransportCancel',
        meta: { title: '取消运输', code: 'trans_cancel', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
      },
    ],
  },
  {
    path: '/carManage',
    component: Layout,
    redirect: '/carManageList',
    meta: { title: '车辆管理', hasSubmenu: true, icon: 'el-icon-postcard' },
    children: [
      {
        path: '/gateRecordIn',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/gateRecordIn'),
        name: 'GateRecordIn',
        meta: { title: '闸道入场记录', code: 'gate_in_record', role: 'GROUP_MANAGER' },
      },
      {
        path: '/gateRecordOut',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/gateRecordOut'),
        name: 'GateRecordOut',
        meta: { title: '闸道出场记录', code: 'gate_out_record', role: 'GROUP_MANAGER' },
      },
      {
        path: '/gateRecordManual',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/gateRecordManual'),
        name: 'GateRecordManual',
        meta: { title: '手动开闸记录', code: 'gate_open_record', role: 'GROUP_MANAGER' },
      },

      {
        path: '/freeVehicleList',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/freeVehicleList'),
        name: 'FreeVehicleList',
        meta: { title: '免费车辆', code: 'car_free', role: 'GROUP_MANAGER' },
        children: [
          {
            path: '/freeVehicleList/addFreeCar',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/addFreeCar'),
            name: 'AddFreeCar',
            meta: { title: '添加免费车辆', role: 'GROUP_MANAGER' },
          },
        ],
      },
      {
        path: '/carManageList',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/carManageList'),
        name: 'CarManageList',
        meta: { title: '车辆管理', code: 'car_manage', role: 'GROUP_MANAGER' },
      },
      {
        path: '/carLoadList',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/carLoadList'),
        name: 'CarLoadList',
        meta: { title: '核载修改', code: 'capacity_modify', role: 'GROUP_MANAGER' },
        children: [
          {
            path: '/carLoadList/carLoadEdit',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/carLoadEdit'),
            name: 'CarLoadEdit',
            meta: { title: '修改车辆核载', role: 'GROUP_MANAGER' },
          },
          {
            path: '/carLoadList/carLoadDetail',
            component: () => import(/* webpackChunkName: "transport" */ '@/views/carManage/carLoadEdit'),
            name: 'CarLoadDetail',
            meta: { title: '车辆核载详情', role: 'GROUP_MANAGER' },
          },
        ],
      },
    ],
  },
  {
    path: '/constructionManage',
    component: Layout,
    redirect: '/constructionManage',
    meta: { title: '基础管理', hasSubmenu: true, icon: 'el-icon-setting' },
    children: [
      {
        path: '/projectManage',
        component: () => import(/* webpackChunkName: "base" */ '@/views/base/projectManage'),
        name: 'ProjectManage',
        meta: { title: '项目管理', code: 'project_manage' },
        children: [
          {
            path: '/projectManage/addProject',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/addProject'),
            name: 'AddProject',
            meta: { title: '添加/修改项目', role: 'GROUP_MANAGER' },
          },
          {
            path: '/projectManage/projectDetail',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/projectDetail'),
            name: 'ProjectDetail',
            meta: { title: '项目详情', role: 'GROUP_MANAGER' },
          },
          {
            path: '/projectManage/addProjectSubAccount',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/addProjectSubAccount'),
            name: 'AddProjectSubAccount',
            meta: { title: '管理子账号', role: 'GROUP_MANAGER' },
          },
        ],
      },

      {
        path: '/projectStatusManage',
        component: () => import(/* webpackChunkName: "base" */ '@/views/base/projectStatusManage'),
        name: 'ProjectManage',
        meta: { title: '项目状态管理', code: 'project_status_manage' },
        children: [
          {
            path: '/projectStatusManage/createProApply',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/createProApply'),
            name: 'CreateProApply',
            meta: { title: '申请添加/修改项目状态', role: 'GROUP_MANAGER' },
          },
          // {
          //   path: '/projectManage/projectDetail',
          //   component: () => import(/* webpackChunkName: "base" */ '@/views/base/projectDetail'),
          //   name: 'ProjectDetail',
          //   meta: { title: '项目详情', role: 'GROUP_MANAGER' },
          // },
        ],
      },

      {
        path: '/projectStatusApprove',
        component: () => import(/* webpackChunkName: "base" */ '@/views/base/projectStatusApprove'),
        name: 'ProjectStatusApprove',
        meta: { title: '项目状态审批', code: 'project_status_approve' },
        children: [
          {
            path: '/projectStatusApprove/checkProApply',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/checkProApply'),
            name: 'CheckProApply',
            meta: { title: '修改项目状态申请详情', role: 'GROUP_MANAGER' },
          },
        ],
      },
      {
        path: '/qtcManage',
        component: () => import(/* webpackChunkName: "base" */ '@/views/base/qtcManage'),
        name: 'QtcManage',
        meta: { title: '弃土场管理', code: 'qtc_manage', role: 'GROUP_MANAGER' },
        children: [
          {
            path: '/qtcManage/addQtc',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/addQtc'),
            name: 'AddQtc',
            meta: { title: '添加/修改弃土场', role: 'GROUP_MANAGER' },
          },
          {
            path: '/qtcManage/qtcInfo',
            component: () => import(/* webpackChunkName: "base" */ '@/views/base/qtcInfo'),
            name: 'QtcInfo',
            meta: { title: '弃土场详情', role: 'GROUP_MANAGER' },
          },

        ],
      },
      {
        path: '/userManage',
        component: () => import(/* webpackChunkName: "base" */ '@/views/base/userManage'),
        name: 'UserManage',
        meta: { title: '用户管理', code: 'manager_manage' },
      },
    ],
  },
  {
    path: '/sgfManage',
    component: Layout,
    redirect: '/sgfManage',
    meta: { title: '施工方管理', hasSubmenu: true, icon: 'el-icon-folder' },
    children: [
      {
        path: '/sgfManage',
        component: () => import(/* webpackChunkName: "base" */ '@/views/construction/sgfManage'),
        name: 'SgfManage',
        meta: { title: '施工方管理', code: 'p_sgf_management', role: 'GROUP_MANAGER,PROJECT_MANAGER' },
      },
    ],
  },
  {
    path: '/qtcManage',
    component: Layout,
    redirect: '/entraceManage',
    meta: { title: '弃土场管理', hasSubmenu: true, icon: 'el-icon-potato-strips' },
    children: [
      {
        path: '/entraceManage',
        component: () => import(/* webpackChunkName: "base" */ '@/views/qtcManage/entraceManage'),
        name: 'EntraceManage',
        meta: { title: '入场统计', code: 'rc_management', role: 'QTC_MANAGER' },
        children: [
          {
            path: '/entraceManage/entraceManageDetail',
            component: () => import(/* webpackChunkName: "base" */ '@/views/qtcManage/entraceManageDetail'),
            name: 'EntraceManageDetail',
            meta: { title: '入场统计明细', role: 'QTC_MANAGER' },
          },
          {
            path: '/entraceManage/resetQtcAccount',
            component: () => import(/* webpackChunkName: "home" */ '@/views/base/resetPwd'),
            name: 'ResetQtcAccount',
            meta: { title: '账号设置', role: 'QTC_MANAGER' },
          },
        ],
      },
      {
        path: '/entraceRecord',
        component: () => import(/* webpackChunkName: "base" */ '@/views/qtcManage/entraceRecord'),
        name: 'EntraceRecord',
        meta: { title: '入场记录', role: 'QTC_MANAGER', code: 'rc_record' },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    isShow: false,
    meta: { title: '登陆', hasSubmenu: true, icon: 'el-icon-truck' },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
  },
];

/**
 * 判断将要进入的页面是否有权限
 * @param {*} roles 权限内容 QTC_MANAGER，弃土场，undefined，表示未设置，所有用户均可访问
 * @return {boolean} true 有权限，false 无权限
 */
// const isRights = (roles) => {
//   const userInfo = storage.get('userInfo');
//   const { role } = userInfo;
//   if (userInfo) {
//     // 设置权限的路由
//     if (roles) {
//       const roleList = roles.split(',');
//       return roleList.includes(role);
//     }
//     // 没有设置权限的路由都可以访问
//     return true;
//   }
//   return false;
// };
/**
 * 设置页面是否展示底部区域
 * @param {String} to 路由名
 */
const setFooter = (to) => {
  const routerName = to.name;
  const isOnlyBack = Boolean(to.query.onlyBack);
  const flag = Object.keys(pageFooterConfig).includes(routerName);
  let footerInfo = pageFooterConfig[routerName] || {};
  footerInfo.isShowFooter = flag;
  if (flag) {
    if (isOnlyBack) {
      footerInfo = {
        isShowFooter: flag,
        cancelText: '返回',
      };
    }
  }
  // vueX改变页面底部区域配置
  store.commit('SET_FOOTERINFO', footerInfo);
};

export const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('to', to);
  const userInfo = storage.get('userInfo') || {};
  // const isQtcUser = userInfo.role === 'QTC_MANAGER';
  setFooter(to);
  // 如果去的是登陆页则不用管
  if (to.name === 'Login') {
    next();
    // 如果没有用户信息
  } else if (JSON.stringify(userInfo) === '{}') {
    next({ name: 'Login' });
  // 如果当前用户是弃土场用户
  } else {
    next();
  }
  //  else if (isQtcUser) {
  //   // 有权限不管理
  //   if (isRights(to.meta.role)) {
  //     next();
  //   } else { // 无权限跳转到入场记录
  //     next({ name: 'EntraceManage' });
  //   }
  // // 不是弃土场用户，项目、或集团
  // } else if (isRights(to.meta.role)) {
  //   // 有权限不管理
  //   next();
  // } else {
  //   // 无权限跳转到首页
  //   // next({ name: 'Home' });
  //   next();
  // }

  // 通过自定义方法获取用户 token 用来判断用户登录状态
  // const token = getToken();
  // const userInfo = storage.get('userInfo') || null;
  // if (!userInfo && to.name !== 'Login') {
  //   // 如果没有登录而且前往的页面不是登录页面，跳转到登录页
  //   next({ name: 'Login' });
  // } else if (!userInfo && to.name === 'Login') {
  //   // 如果没有登录而且前往的页面是登录页面，跳转到登录页面
  //   // 这里有一个坑，一定要注意这一步和上一步得分开写
  //   // 如果把前两步判断合并为 if (!token) next({ name:login })
  //   // 则会形成登录页面无限刷新的错误，具体成因后面解释
  //   next();
  // } else {
  //   // 如果登录了
  //   // if (!store.state.app.hasGetRoute) {
  //   // 如果没有获取路由信息，先获取路由信息而后跳转
  //   // store.dispatch('getRouteList').then(() => {
  //   router.addRoutes(routeList);
  //   // 这里也是一个坑，不能使用简单的 next()
  //   // 如果直接使用 next() 刷新后会一直白屏
  //   next({ ...to, replace: true });
  //   //   });
  //   // } else {
  //   //   // 如果已经获取路由信息，直接跳转
  //   //   next();
  //   // }
  // }
});
export default router;
