/* eslint-disable no-param-reassign */
import axios from 'axios'; // 引入axios
import Qs from 'qs'; // 引入qs模块，用来序列化post类型的数据
import { Message, Loading } from 'element-ui'; // 提示框
import storage from 'good-storage';
import { checkStatus, getToken, formatUrl } from './utils'; // 处理函数

// 创建axios实例
const instance = axios.create({
  // baseURL: '',
  timeout: 600000,
  // `transformRequest` 允许在向服务器发送前，修改请求数据
  transformRequest: [function (data) {
    // 对 data 进行任意转换处理
    return data;
  }],
  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  transformResponse: [function (data) {
    // 对 data 进行任意转换处理
    return JSON.parse(data);
  }],

});
let loadingInstance;
// 实例添加请求拦截器
instance.interceptors.request.use((config) => {
  // 在发送请求之前做处理...
  loadingInstance = Loading.service({ text: '请稍后...', background: 'rgba(255,255,255,0.2)' });
  // console.log(Loading);
  // config.headers = Object.assign(config.method === 'get' ? {
  //   Accept: 'application/json',
  //   'Content-Type': 'application/json; charset=UTF-8',
  // } : {
  //   Accept: 'application/json',
  //   'Content-Type': 'application/json; charset=UTF-8',
  // }, config.headers);
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    token: getToken(),
    ...config.headers,
  };

  if (config.method === 'post') {
    const contentType = config.headers['Content-Type'];
    // 根据Content-Type转换data格式
    if (contentType) {
      if (contentType.includes('multipart')) { // 类型 'multipart/form-data;'
        // config.data = data;
      } else if (contentType.includes('json')) { // 类型 'application/json;'
        // 服务器收到的raw body(原始数据) "{name:"nowThen",age:"18"}"（普通字符串）
        config.data = JSON.stringify(config.data);
      } else { // 类型 'application/x-www-form-urlencoded;'
        // 服务器收到的raw body(原始数据) name=nowThen&age=18
        config.data = Qs.stringify(config.data);
      }
    }
  }
  return Promise.resolve(config);
}, (error) => Promise.reject(error));
// 对请求错误做处理...

// 实例添加响应拦截器
instance.interceptors.response.use((response) => {
  // 对响应数据做处理，以下根据实际数据结构改动！！...
  // console.log(response)
  // const { reason_code } = response.data || {};
  // if (reason_code === '001') { // 请求超时，跳转登录页
  //   const instance = Toast('请求超时，即将跳转到登录页面...');
  //   setTimeout(() => {
  //     instance.close();
  //     window.location.href = '/login';
  //   }, 3000)
  // }
  console.log(response);
  return Promise.resolve(checkStatus(response));
},
(error) => {
  console.error('error', error);
  // 对响应错误做处理...
  if (error.response) {
    return Promise.reject(checkStatus(error.response));
  } if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error('请求超时'));
  }
  return Promise.reject(new Error('未知错误'));
});

// eslint-disable-next-line consistent-return
const request = async function (opt) {
  try {
    const options = {
      method: 'get',
      ifHandleError: true, // 是否统一处理接口失败(提示)
      ...opt,
    };
    // 匹配接口前缀 开发环境则通过proxy配置转发请求； 生产环境根据实际配置
    // options.baseURL = `${process.env.VUE_APP_BASE_URL}/api`; // 开发 or 生产;
    options.baseURL = `${process.env.VUE_APP_BASE_URL}/api`; // 开发 or 生产;
    // console.log(options);
    const res = await instance(options);
    loadingInstance.close();

    // @ts-ignore
    if (res.code === 1) {
      // const errState = {
      //   20045: '工会账号不存在~',
      //   20046: '密码不正确~',
      //   20056: '计划编码已经使用',
      // };
      // 自定义参数，是否允许全局提示错误信息
      // console.log(res.error || '未知错误');
      // const errMsg = errState[res.a] ? errState[res.a] : '未知错误';
      // @ts-ignore
      return Promise.resolve(res.data);
    }
    const codes = [-5, -6, 6002];
    if (codes.includes(res.code)) {
      storage.remove('userInfo');
    }
    Message.error(res.message);
    return Promise.reject(res);
  } catch (err) {
    console.log(err.msg || '请求处理失败！');
    loadingInstance.close();
    Message.error(err.msg || '请求处理失败！');
    // if (options.ifHandleError) { // 自定义参数，是否允许全局提示错误信息
    // }
    return Promise.reject(err);
  }
};

export const get = (url, data) => request({ method: 'get', url, data });

export const post = (url, data) => {
  if (url.indexOf('{') > -1 && data) {
    url = formatUrl(url, data);
  }
  return request({ method: 'post', url, data });
};

const checkExportDataParams = (params) => {
  if (!params.year) {
    Message.warning('请选择年份');
    return false;
  }
  if (!params.month) {
    Message.warning('请选择月份');
    return false;
  }
  if (!params.projectUuid) {
    Message.warning('请选择项目');
    return false;
  }
  return true;
};

export const exportData = (url, params, fileName, isChecked = true) => {
  if (isChecked) {
    const checkResult = checkExportDataParams(params);
    if (!checkResult) return;
  }
  const network = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}/api/`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      token: getToken(),
    },
  });
  network.defaults.withCredentials = false;
  console.log('network', network);

  const link = document.createElement('a');

  network.post(url, {
    ...params,
    page: 1,
    size: 1000,
  },
  { responseType: 'blob' }).then((res) => {
    // 创建Blob对象，设置文件类型
    const blob = new Blob([res.data], { type: 'application/json' });
    const objectUrl = URL.createObjectURL(blob); // 创建URL
    link.href = objectUrl;
    link.download = `${fileName}.xlsx`; // 自定义文件名
    document.body.appendChild(link);
    link.click(); // 下载文件
    URL.revokeObjectURL(objectUrl); // 释放内存
    document.body.removeChild(link);
  });
};
