/*
 * @Author: 许波
 * @Date: 2021-07-31 18:31:12
 * @LastEditTime: 2022-11-09 22:16:22
 */
import { post } from '../utils/index';

// 审批登记申请
export const $gp_groupApprove = (params) => post('/api/group/sgf/web/v1/approve', params);

// 获取施工方登记申请列表
export const $gp_groupApply = (params) => post('/api/group/sgf/web/v1/list/apply', params);

// 获取已拒绝/已移除列表
export const $gp_groupBreak = (params) => post('/api/group/sgf/web/v1/list/break', params);

// 获取已经登记的施工方列表
export const $gp_groupRelation = (params) => post('/api/group/sgf/web/v1/list/checkIn', params);

// 移除施工方
export const $gp_groupRemove = (params) => post('/api/group/sgf/web/v1/remove', params);

// 删除已移除/已拒绝日志
export const $gp_groupDelBreak = (params) => post('/api/group/sgf/web/v1/del/break', params);

// 获取待审核的登记申请详情
export const $gp_groupApplyDetail = (params) => post('/api/group/sgf/web/v1/detail/apply', params);

/** * pro ****** */

// 审批登记申请
export const $gp_proApprove = (params) => post('/api/project/sgf/web/v1/approve', params);

// 删除已移除/已拒绝日志
export const $gp_proDelBreakLog = (params) => post('/api/project/sgf/web/v1/del/break', params);

// 获取待审核的登记申请详情
export const $gp_proApplyDetail = (params) => post('/api/project/sgf/web/v1/detail/apply', params);

// 获取待审核的登记申请列表
export const $gp_profApplyList = (params) => post('/api/project/sgf/web/v1/list/apply', params);

// 获取项目已审核通过，待集团审核的登记申请列表
export const $gp_proApplyGroupList = (params) => post('/api/project/sgf/web/v1/list/apply/group', params);

// 获取已拒绝/已移除列表
export const $gp_proBreakList = (params) => post('/api/project/sgf/web/v1/list/break', params);

// 获取已登记的施工方列表
export const $gp_proCheckinList = (params) => post('/api/project/sgf/web/v1/list/checkIn', params);

// 获取施工方列表
export const $gp_proRelation = (params) => post('/api/project/sgf/web/v1/list/relation', params);

// 重置施工方密码, uuid为sgfUuid
export const $gp_proRestSgfPwd = (params) => post('/api/project/sgf/web/v1/reset/password', params);

// 移除已登记的施工方， uuid为sgfUuid
export const $gp_proRemoveSgfPwd = (params) => post('/api/project/sgf/web/v1/remove', params);
