/*
 * @Author: your name
 * @Date: 2021-02-10 20:14:01
 * @LastEditTime: 2023-01-28 23:34:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \data-admin\src\utils\utils.js
 */
import storage from 'good-storage';

const RESPONE_STATUS = {
  1: '远程服务响应失败,请稍后重试',
  400: '错误请求',
  401: '访问令牌无效或已过期',
  403: '拒绝访问',
  404: '资源不存在',
  405: '请求方法未允许',
  408: '请求超时',
  500: '访问服务失败',
  501: '未实现',
  502: '无效网关',
  503: '服务不可用',
  1000: '连接错误',
};
export function checkStatus(response) {
  const status = response.status || -1000; // -1000 自己定义，连接错误的status
  if ((status >= 200 && status < 300) || status === 304) {
    // 如果http状态码正常，则直接返回数据
    return response.data;
  }
  return {
    status,
    msg: RESPONE_STATUS[status],
  };
}

const isDev = process.env.NODE_ENV === 'development'; // 开发 or 生产

// 匹配接口前缀
export function autoMatch(prefix) {
  let baseUrl = '';
  if (isDev) {
    // 开发环境 通过proxy配置转发请求；
    baseUrl = `/${prefix || 'default'}`;
  } else {
    // 生产环境 根据实际配置 根据 prefix 匹配url;
    // 配置来源 根据实际应用场景更改配置。(1.从全局读取；2.线上配置中心读取)
    switch (prefix) {
      case 'baidu':
        baseUrl = window.LOCAL_CONFIG.baidu;
        break;
      case 'alipay':
        baseUrl = window.LOCAL_CONFIG.alipay;
        break;
      default:
        baseUrl = window.LOCAL_CONFIG.default;
    }
  }
  return baseUrl;
}

export function deepClone(obj) {
  const tmp = JSON.stringify(obj);
  const result = JSON.parse(tmp);
  return result;
}

export function globalClose(that) {
  console.log(that.$route.path);
  const pathArr = that.$route.path.split('/');
  if (pathArr.length > 2) {
    that.$router.back();
  } else {
    that.$router.push({ name: 'Home' });
  }
}

export function getRole() {
  const { role } = storage.get('userInfo');
  return role;
}
export function roleCodeList() {
  const { menuList } = storage.get('userInfo');
  console.log('menuList', menuList);
  const menuLists = menuList.map((item) => [...item.menuList]).flat();
  const codeList = menuLists.map((item) => item.code);
  codeList.push('home');
  console.log('codeList', codeList);
  return codeList;
  // if (role === 'GROUP_MANAGER') {
  // }
  // if (role === 'PROJECT_MANAGER') {
  // return ['home', 'note_assign', 'assign_record',
  // 'note_statistics', 'transport_record', 'transport_statistics', 'p_sgf_management'];
  // }
  // return ['rc_management', 'rc_record'];
}

export function getToken() {
  if (storage.get('userInfo')) {
    const { token } = storage.get('userInfo');
    return token;
  }
  return '';
}

// 是否有该菜单访问权限 @return boolean
export function isRole(code) {
  const codeList = roleCodeList();
  console.log(codeList);
  return codeList.includes(code);
}

// 用于生成uuid
function S4() {
  // eslint-disable-next-line no-bitwise
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}
function guid() {
  return (`${S4() + S4()}${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}`);
  // return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}

export function getUUID() {
  return guid();
}
export function createMonths() {
  const months = [];
  for (let i = 1; i < 13; i += 1) {
    months.push({ label: `${i}月`, value: i });
  }
  return months;
}
export function createDays(month, year) {
  const date = new Date();
  const y = date.getFullYear();
  const maxMonth = [1, 3, 5, 7, 8, 10, 12];
  const years = year || y;
  const days = [];
  const createDay = (num) => {
    for (let i = 1; i <= num; i += 1) {
      days.push({ label: `${i}日`, value: i });
    }
  };
  if (maxMonth.includes(month)) {
    createDay(31);
  } else if (month === 2) {
    if (years % 4 === 0) {
      createDay(29);
    } else {
      createDay(28);
    }
  } else {
    createDay(30);
  }
  console.log(days);
  return days;
}
// 获取年份，近len年
export function createYears(len) {
  const date = new Date();
  let y = date.getFullYear();
  const years = [{ label: `${y}年`, value: y }];
  for (let i = 0; i < len; i += 1) {
    y -= 1;
    years.push({ label: `${y}年`, value: y });
  }
  return years;
}

/**
 * 格式化日期
 * @param {Date} date Date对象
 * @param {string} fmt 返回格式 'yyyy-MM-dd HH:mm:ss SSS q'
 */
export const formatDateByDate = (date, fmt = 'MM-dd HH:mm') => {
  if (date instanceof Date) {
    let format = fmt;
    const o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'H+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    Object.entries(o).forEach(([key, val]) => {
      if (new RegExp(`(${key})`).test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? val : `00${val}`.substr(`${val}`.length));
      }
    });
    return format;
  }
  return date;
};

// 格式化时间戳
export const formatDate = (stamp, fmt = 'yyyy-MM-dd HH:mm') => {
  if (!stamp) {
    return '';
  }
  const date = new Date(+stamp);
  const resTime = formatDateByDate(date, fmt);
  return resTime;
};

export const formatTimeRangeStr = (rangeStr, simpleFlag) => {
  if (rangeStr.indexOf(',') === -1) return '';
  const [start, end] = rangeStr.split(',');
  const startDate = new Date(+start * 1000);
  const endDate = new Date(+end * 1000);
  return `${formatDateByDate(startDate, simpleFlag ? 'MM-dd HH:mm' : 'yyyy-MM-dd HH:mm')}-${formatDateByDate(endDate, 'HH:mm')}`;
};

export const getSumData = (param) => {
  const { columns, data } = param;
  // console.log(data);
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '总计';
      return;
    }
    const values = data.map((item) => Number(item[column.property]));
    // console.log(values);
    // eslint-disable-next-line no-restricted-globals
    if (!values.every((value) => isNaN(value))) {
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(value)) {
          return prev + curr;
        }
        return prev;
      }, 0);
      sums[index] += 0;
    } else {
      sums[index] = data.length;
    }
  });

  return sums;
};

/**
 * @description: 获取两个日期之间的天数
 * @param {Date} date1,开始日期
 * @param {Date} date2,结束日期
 * @return {Number} days 天数
 */
export const getDaysBetween = (date1, date2) => {
  const startDate = Date.parse(date1);
  const endDate = Date.parse(date2);
  const days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
};
export const getUploadParam = () => ({
  token: getToken(),
  api: `${process.env.VUE_APP_BASE_URL}/api/file/v3/upload`,
});
/**
 * 函数防抖
 * 【小知识】 call 、bind 、 apply 三个函数的第一个参数都是 this 的指向对象
 * call 的参数是直接放进去的，第二第三第 n 个参数全都用逗号分隔，直接放到后面
 * apply 的所有参数都必须放在一个数组里面传进去
 * bind 除了返回是函数以外，它的参数和 call 一样
 * @param {function} func,要调用的函数
 * @param {number} delay, 要延迟执行的时间 单位[ms]
 * @param {boolean} immediate 第一次是否立即执行
 * @return {function}
*/
export const debounce = (func, delay, immediate) => {
  let timer = null;
  // 取得调用方法返回的值
  let result;
  const debounceFun = function () { // 此处如用=>写法会拿不到调用的对象
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const ctx = this;
    clearTimeout(timer);
    if (immediate) {
      const callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, delay);
      if (callNow) result = func.apply(ctx, args);
    } else {
      timer = setTimeout(() => {
        result = func.apply(ctx, args);
      }, delay);
    }
    return result;
  };
  debounceFun.cancel = function () {
    clearTimeout(timer);
    timer = null;
  };
  return debounceFun;
};
/**
 * @description: 下载网络图片方法
 * @param {String} url,要下载的图片网络地址
 * @param {String} name,要保存的图片名称
 */
export const downLoadImg = (url, name) => {
  const image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = function () {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    const imgUrl = canvas.toDataURL('image/png'); // 得到图片的base64编码数据
    const a = document.createElement('a'); // 生成一个a元素
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.download = name || 'photo'; // 设置图片名称
    a.href = imgUrl; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = url;
};

/**
 * @description: 获取文件的blob
 * @param {String} url,要下载的文件网络地址
 * @param {Function} cb,回调方法，获取返回结果
 */
const getBlob = (url, cb) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
};
/**
 * @description: 下载blob
 * @param {String} url,要下载的文件网络地址
 * @param {Function} cb,回调方法，获取返回结果
 */
const saveAs = (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
};
/**
 * @description: 下载文件方法
 * @param {String} url,要下载的图片网络地址
 * @param {String} fileName,要保存的图片名称
 */
export const downloadFile = (url, fileName) => {
  getBlob(url, (blob) => {
    saveAs(blob, fileName);
  });
};

/**
 * @description: 处理API中的请求地址中带{}变量的地址
 * @param {string} url
 * @param {object} data
 * @return {string} path
 */
export const formatUrl = (url, data) => {
  const field = url.substring(url.indexOf('{') + 1, url.indexOf('}'));
  let val = '';
  val = data[field];
  const path = `${url.substring(0, url.indexOf('{'))}${val}`;
  return path;
};
