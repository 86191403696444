/*
 * @Author: 许波
 * @Date: 2021-02-08 22:36:56
 * @LastEditTime: 2023-01-29 01:25:51
 */
import { post } from '../utils/index';

// 创建弃土场，如果录入了账号，则同时创建弃土场管理员账号
export const $gp_createQTC = (params) => post('/qtc/v3/create', params);

// 获取弃土场列表
export const $gp_getQtcList = (params) => post('/qtc/v3/page', params);

// 修改弃土场，如果录入了账号，则同时修改弃土场管理员账号
export const $gp_editQtcInfo = (params) => post('/qtc/v3/modify/{qtcUuid}', params);

// 获取弃土场信息
export const $gp_getQtcInfo = (params) => post('/qtc/v3/modify/{qtcUuid}', params);

// 删除弃土场，如有管理员账号，则同时删除
export const $gp_delQtcInfo = (params) => post('/qtc/v3/del/{qtcUuid}', params);

// 重置弃土场密码
export const $gp_resetQtcPwd = (params) => post('/qtc/v3/reset-password/{qtcUuid}', params);

// 获取运输记录
export const $gp_qtcTransList = (params) => post('/api/qtc/trans/web/v1/list', params);

// 按车辆统计
export const $gp_qtcTransListByCar = (params) => post('/api/qtc/trans/web/v1/statistics/car', params);

// 按项目统计
export const $gp_qtcTransListByPro = (params) => post('/api/qtc/trans/web/v1/statistics/project', params);

// 按施工方统计
export const $gp_qtcTransListBySgf = (params) => post('/api/qtc/trans/web/v1/statistics/sgf', params);

// 获取运输统计
export const $gp_qtcTransListStatistics = (params) => post('/api/qtc/trans/web/v1/statistics/list', params);

// 获取当前登录的弃土场信息
export const $gp_getCurQtcInfo = (params) => post('/api/qtc/account/web/v1/detail/current', params);

// 获取当前登录的弃土场信息
export const $gp_devicePassword = (params) => post('/api/group/qtc/web/v1/reset/devicePassword', params);

// 获取当前登录的弃土场信息
export const $gp_transListTotal = (params) => post('/api/qtc/trans/web/v1/list/total', params);
