/*
 * @Author: 许波
 * @Date: 2021-02-01 20:25:24
 * @LastEditTime: 2022-11-18 00:16:04
 */
import Vue from 'vue';

import VueCookies from 'vue-cookies';
import App from './App.vue';
import { router } from './router';
import store from './store';
import './plugins/echarts';
import './plugins/element';
import './scss/global.scss';

Vue.use(VueCookies);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
