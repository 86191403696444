/*
 * @Author: 许波
 * @Date: 2021-07-11 18:41:06
 * @LastEditTime: 2023-01-27 22:23:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \data-admin\src\config\menuData.js
 */
import { deepClone, roleCodeList } from '../utils/utils';
import { routes } from '../router/index';

function formatRoute(menuArr, codeList) {
  const routeList = menuArr.map((route) => {
    if (route.path !== '/login') {
      const children = route.children.map((child) => {
        let isShow = false;
        if (child.meta.code) {
          isShow = codeList.includes(child.meta.code);
        }
        return { ...child, isShow };
      });
      const hasShowChild = children.find((item) => item.isShow);
      const pIsShow = Boolean(hasShowChild);
      return { ...route, children, isShow: pIsShow };
    }
    return route;
  });
  return routeList;
}
const menuList = () => {
  let menuSource = deepClone(routes);
  const codeList = roleCodeList();
  console.log(codeList);
  menuSource = formatRoute(menuSource, codeList);
  console.log('mmm', menuSource);
  return menuSource;
};

export default menuList;
