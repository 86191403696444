/*
 * @Author: 许波
 * @Date: 2021-08-16 23:53:31
 * @LastEditTime: 2023-01-29 23:42:28
 */

import { post } from '../utils/index';

// 分配票据
export const $gp_groupAllocate = (params) => post('/coupon-apply/v3/create', params);

// 获取票据分配申请详情
export const $gp_getAllocateDetail = (params) => post('/coupon-apply/v3/detail/{applyUuid}', params);

// 获取分配记录详情
export const $gp_groupAllocateDetail = (params) => post('/api/group/coupon/web/v1/detail/record', params);

// 获取分配记录列表
export const $gp_groupAllocateRecordList = (params) => post('/api/group/coupon/web/v1/list/record', params);

// 审批分配记录
export const $gp_groupCouponApprove = (params) => post('/coupon-apply/v3/approve/{applyUuid}', params);

// 获取票据分配预览
export const $gp_groupReview = (params) => post('/api/group/coupon/web/v1/review', params);

// 统计明细
export const $gp_groupStatistics = (params) => post('/api/group/coupon/web/v1/statistics', params);

// 按车辆统计
export const $gp_groupCarStatistics = (params) => post('/api/group/coupon/web/v1/statistics/car', params);

// 按项目统计
export const $gp_groupProStatistics = (params) => post('/coupon-statistics/v3/page', params);

// 按施工方统计
export const $gp_groupSgfStatistics = (params) => post('/api/group/coupon/web/v1/statistics/sgf', params);

/** *PRO */

// 分配票据
export const $gp_proAllocate = (params) => post('/api/project/coupon/web/v1/allocate', params);

// 获取分配记录详情
export const $gp_proAllocateDetail = (params) => post('/api/project/coupon/web/v1/detail/record', params);

// 获取分配记录列表
export const $gp_proAllocateRecordList = (params) => post('/coupon-apply/v3/page', params);

// 获取票据分配预览
export const $gp_proReview = (params) => post('/api/project/coupon/web/v1/review', params);

// 获取票据分配预览
export const $gp_proCarStatistics = (params) => post('/api/project/coupon/web/v1/statistics/car', params);

// 获取票据分配预览
export const $gp_proSgfStatistics = (params) => post('/api/project/coupon/web/v1/statistics/sgf', params);
