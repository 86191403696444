/*
 * @Author: 许波
 * @Date: 2021-08-30 22:31:03
 * @LastEditTime: 2023-02-10 22:59:37
 */
import { post } from '../utils/index';
// 删除车辆类型
export const $gp_groupDelWhiteCar = (params) => post('/api/group/gate/web/v1/white_list/del', params);
// 添加车辆类型
export const $gp_groupAddWhiteCar = (params) => post('/api/group/gate/web/v1/white_list/add', params);

// 修改免费车辆
export const $gp_groupEditWhiteCar = (params) => post('/api/group/gate/web/v1/white_list/modify', params);
// 获取车辆类型
export const $gp_groupCarTypeList = (params) => post('/api/group/gate/web/v1/car_type/list', params);

// 获取车牌类型
export const $gp_groupCardTypeList = (params) => post('/api/group/gate/web/v1/card_type/list', params);

// 获取白名单记录
export const $gp_groupWhiteList = (params) => post('/gate/v3/page/white', params);

//  获取道闸入场记录
export const $gp_gateRecordIn = (params) => post('/gate/v3/page/in', params);

//  获取道闸出场记录
export const $gp_gateRecordOut = (params) => post('/gate/v3/page/out', params);

//  获取道闸手动开闸记录
export const $gp_gateRecordManual = (params) => post('/gate/v3/page/out', params);

//  撤销方量修改请求
export const $gp_capacityCancel = (params) => post('/api/group/car/web/v1/update-capacity/cancel', params);
//  修改运输方量
export const $gp_capacityCreate = (params) => post('/capacity-apply/v3/create', params);

//  修改运输方量
export const $gp_capacityPage = (params) => post('/capacity-apply/v3/page', params);

// 根据车牌号获取车辆信息
export const $gp_getCarInfoByLicense = (params) => post('/car/v3/detail/{carNo}', params);

// 获取核载修改详情
export const $gp_getCarDetail = (params) => post('/capacity-apply/v3/detail/{applyUuid}', params);

// 获取与车辆相关的项目列表
export const $gp_getProInfoByLicense = (params) => post('/project/v3/list/capacity-apply/{carUuid}', params);

// 获取方量修改请求详情
export const $gp_capacityDetail = (params) => post('/api/group/car/web/v1/update-capacity/detail', params);
// 创建运输记录集合
export const $gp_groupTransCreate = (params) => post('/trans-apply/v3/create', params);

// 删除运输记录集合，根据运输集合uuid删除删除运输记录集合
export const $gp_groupTransDel = (params) => post('/api/group/trans/web/v1/del', params);

// 删除运输记录集合中的记录条目
export const $gp_groupTransDelRecord = (params) => post('/api/group/trans/web/v1/del/record', params);

// 修改运输记录集合
export const $gp_groupTransUpdate = (params) => post('/api/group/trans/web/v1/update', params);

// 获取运输记录
export const $gp_groupTransList = (params) => post('/api/group/trans/web/v1/list', params);

// 按项目-弃土场统计的合计
export const $gp_groupTransStatisticsByProAndQtc = (params) => post('/api/group/trans/web/v1/statistics/project-qtc/total', params);

// 按项目-弃土场统计的合计
export const $gp_groupProAndQtcList = (params) => post('/api/group/trans/web/v1/statistics/project-qtc', params);

// 按车辆统计
export const $gp_groupTransStatisticsByCar = (params) => post('/api/group/trans/web/v1/statistics/car', params);

// 按项目统计
export const $gp_groupTransStatisticsByPro = (params) => post('/api/group/trans/web/v1/statistics/project', params);

// 按弃土场统计
export const $gp_groupTransStatisticsByQtc = (params) => post('/api/group/trans/web/v1/statistics/qtc', params);

// 按施工方统计
export const $gp_groupTransStatisticsBySgf = (params) => post('/api/group/trans/web/v1/statistics/sgf', params);

// 运输统计
export const $gp_groupTransStatistics = (params) => post('/api/group/trans/web/v1/statistics/list', params);

// 运输统计统计
export const $gp_groupTransStatisticsTotal = (params) => post('/api/group/trans/web/v1/statistics/list/total', params);

// 获取所有的项目数据
export const $gp_groupAllProList = (params) => post('/api/group/project/web/v1/list/all', params);

// 获取所有的弃土场数据
export const $gp_groupAllQtcList = (params) => post('/qtc/v3/list/gate', params);

// 获取所有的施工方数据
export const $gp_groupAllSgfList = (params) => post('/api/group/sgf/web/v1/list/all/project', params);

// 获取关键字模糊匹配的车辆列表
export const $gp_groupCarByKey = (params) => post('/api/group/car/web/v1/query/key', params);

// 获取车辆列表（以及车辆与弃土场的关系）
export const $gp_groupCarList = (params) => post('/car/v3/page', params);

// 解绑车辆
export const $gp_groupCarUnbind = (params) => post('/api/group/car/web/v1/unbind', params);

// 是否有正在运输的记录，有正在运输的记录，返回true
export const $gp_groupCarTransporting = (params) => post('/api/group/trans/web/v1/has/transporting', params);

// 获取关键字模糊匹配的车辆列表
export const $gp_groupTransDetailByUuid = (params) => post('/api/group/trans/web/v1/detail/collection', params);

// 导出运输明细
export const $gp_gropuTransExport = (params) => post('/api/group/trans/web/v1/export', params);

// 获取取消的运输记录列表
export const $gp_proTransCancelList = (params) => post('/trans/v3/page/cancel', params);

// 按车辆统计
export const $gp_proTransStatisticsByCar = (params) => post('/api/project/trans/web/v1/statistics/car', params);

// 按弃土场统计
export const $gp_proTransStatisticsByQtc = (params) => post('/api/project/trans/web/v1/statistics/qtc', params);

// 按施工方统计
export const $gp_proTransStatisticsBysgf = (params) => post('/api/project/trans/web/v1/statistics/sgf', params);

// 运输统计
export const $gp_proTransStatistics = (params) => post('/api/project/trans/web/v1/statistics/list', params);

// 按项目统计合计
export const $gp_statisticsTotalByPro = (params) => post('/api/group/trans/web/v1/statistics/project/total', params);

// 按项施工方计合计
export const $gp_statisticsTotalBySgf = (params) => post('/api/group/trans/web/v1/statistics/sgf/total', params);

// 按车辆统计合计
export const $gp_statisticsTotalByCar = (params) => post('/api/group/trans/web/v1/statistics/car/total', params);

// 按弃土场统计合计
export const $gp_statisticsTotalByQtc = (params) => post('/api/group/trans/web/v1/statistics/qtc/total', params);

// 获取运输记录集合
export const $gp_groupTransCollection = (params) => post('/trans-apply/v3/page', params);

// 获取补录预审列表运输记录集合
export const $gp_groupPreApprove = (params) => post('/trans-apply/v3/page/pre', params);

// 预审人工登记
export const $gp_transPreApprove = (params) => post('/trans-apply/v3/pre-approve/{transUuid}', params);

// 获取运输记录集合
export const $gp_getSpace = (params) => post('/trans-apply/v3/list/space', params);

// 获取运输记录集合
export const $gp_getTransApplyDetail = (params) => post('/trans-apply/v3/detail/{transUuid}', params);

// 审批人工登记
export const $gp_transApprove = (params) => post('/trans-apply/v3/approve/{transUuid}', params);

// 获取运输记录
export const $gp_proTransList = (params) => post('/trans/v3/page', params);

// 人工补录
export const $gp_proTransListAll = (params) => post('/trans-apply/v3/page/all', params);

// 按天统计运输记录
export const $gp_transStatisticsDay = (params) => post('/trans-statistics/v3/page/day', params);

// 按天统计运输记录合计
export const $gp_transStatisticsDayTotal = (params) => post('/trans-statistics/v3/day/total', params);

// 按天统计运输记录合计
export const $gp_transStatisticsMonthTotal = (params) => post('/trans-statistics/v3/month/total', params);

// 按按天按车统计运输记录
export const $gp_transStatisticsDaySub = (params) => post('/trans-statistics/v3/page/day/sub', params);

// 按按天按车统计运输记录
export const $gp_transStatisticsSubTotal = (params) => post('/trans-statistics/v3/day/sub/total', params);

// 按月统计运输记录
export const $gp_transStatisticsMonth = (params) => post('/trans-statistics/v3/page/month', params);

// 按月统计运输记录
export const $gp_transStatisticsPro = (params) => post('/trans-statistics/v3/page/project', params);

// 按天统计补录记录
export const $gp_transManualTotal = (params) => post('/trans-manual/v3/total/io', params);
